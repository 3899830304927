// action types
export const APPEND_MENU = 'appendMenu';

// mutation types
export const SET_MENU = 'setListMenu';
export const ADD_MENU = 'addListMenu';
export const RESET_MENU_WHEN_LOGOUT = 'resetMenuWhenLogout';

export default {
  state: {
    listMenu: []
  },
  getters: {
    /**
     * Get list of menu for current page
     * @param state
     * @returns {*}
     */
    listMenu(state) {
      return state.listMenu;
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    menuTitle(state) {
      let last = state.listMenu[state.listMenu.length - 1];
      if (last && last.name) {
        return last.name;
      }
    }
  },
  actions: {
    /**
     * Set the menu list
     * @param state
     * @param payload
     */
    [SET_MENU](state, payload) {
      state.commit(SET_MENU, payload);
    },

    /**
     * Add menu
     * @param state
     * @param payload
     */
    [ADD_MENU](state, payload) {
      if (typeof payload === 'object') {
        payload.forEach(item => state.commit(APPEND_MENU, item));
      } else {
        state.commit(APPEND_MENU, payload);
      }
    },
    [RESET_MENU_WHEN_LOGOUT](state){
      state.commit(RESET_MENU_WHEN_LOGOUT)
    }
  },
  mutations: {
    [APPEND_MENU](state, payload) {
      state.listMenu = [...state.listMenu, payload];
    },
    [SET_MENU](state, payload) {
      state.listMenu = payload;
    },
    [RESET_MENU_WHEN_LOGOUT](state) {
      state.listMenu = [];
    },
  }
};
