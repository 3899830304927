const actions = {
  fetchWarrantyInfo: 'fetchWarrantyInfo',
  updateWarrantyInfo: 'updateWarrantyInfo',
};

const mutations = {
  SET_WARRANTY_INFO: 'SET_WARRANTY_INFO',
  ADD_WARRANTY_INFO: 'ADD_WARRANTY_INFO',
};

const initialState = {
  listBillItem: [],
};

export default {
  state: {
    warrantyInfo: {
      ...initialState,
    },
  },
  getters: {
    getWarrantyInfo(state) {
      return state.warrantyInfo;
    },
  },
  actions: {
    [actions.fetchWarrantyInfo](state, payload) {
      state.commit(mutations.SET_WARRANTY_INFO, payload);
    },
    [actions.updateWarrantyInfo](state, payload) {
      state.commit(mutations.SET_WARRANTY_INFO, payload);
    },
  },
  mutations: {
    [mutations.SET_WARRANTY_INFO](state, payload) {
      state.warrantyInfo = { ...payload };
    },
    [mutations.ADD_WARRANTY_INFO](state) {
      state.warrantyInfo = initialState;
    },
  },
};
