// action types
export const SET_ORDER_DISCOUNT_INFO = 'setOrderDiscountInfo';
export const PURGE_ORDER_DISCOUNT_INFO = 'removeOrderDiscountInfo';

const orderDiscountBase = {
  discountAmount: 0,
  discountType: 1,
  couponCode: null,
  paymentDate: null,
  customerShipFee: 0,
  shipFee: 0,
  subtractPoint: 0,
  subtractPointAmount: null,
  otpCode:null
};

export default {
  state: {
    orderDiscount: {
      ...orderDiscountBase
    }
  },
  getters: {
    /**
     * Get Order discount
     * @param state
     * @returns {*}
     */
    getOrderDiscount(state) {
      return state.orderDiscount;
    }
  },
  actions: {
    [SET_ORDER_DISCOUNT_INFO](state, payload) {
      state.commit(SET_ORDER_DISCOUNT_INFO, payload);
    },
    [PURGE_ORDER_DISCOUNT_INFO](state) {
      state.commit(PURGE_ORDER_DISCOUNT_INFO);
    }
  },
  mutations: {
    [SET_ORDER_DISCOUNT_INFO](state, payload) {
      state.orderDiscount = payload;
    },
    [PURGE_ORDER_DISCOUNT_INFO](state) {
      state.orderDiscount = {...orderDiscountBase};
    }
  }
};
