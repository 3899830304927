// action types
export const SET_INTEREST = 'setInterestZero';
export const SET_INSTALLMENT_PROGRAM = 'setInstallmentProgram';

export const SET_INTEREST_SHINHAN = 'setInterestShinhan';
export const SET_INSTALLMENT_SHINHAN = 'setInstallmentShinhan';

export const PURGE_INTEREST_ZERO = 'removeInterestZero';
export const PURGE_INSTALLMENT_PROGRAM = 'removeInstalmentProgram';

export const PURGE_INTEREST_SHINHAN = 'removeInterestShinhan';
export const PURGE_INSTALLMENT_SHINHAN = 'removeInstallmentShinhan';

const defaultInstallmentProgram = {
  details: [],
  installmentProgramId: null,
  installmentProgramName: null,
  installmentProgramMonthNo: null,
  installmentProgramPrepayPercent: null,
  conversionFeePercent: null,
  prepayPercent: [],
  monthNo: [],
};

const defaultInstallmentShinhan = {
  installApplicationStatus: -1,
  loanApplicationId: null,
};
export default {
  state: {
    isInterestZero: false,
    inInterestShinhan: false,
    program: {
      ...defaultInstallmentProgram,
    },
    isInterestShinhan: false,
    shinhan: {
      ...defaultInstallmentShinhan,
    },
    installmetProductPrice: 0,
  },
  getters: {
    /**
     * get order status
     * @param state
     * @returns {*}
     */
    getInstallmentInterestZero(state) {
      return state.isInterestZero;
    },
    getInstallmentInterestShinhan(state) {
      return state.isInterestShinhan;
    },
    getInstallmentProgram(state) {
      return state.program;
    },
    getInstallmentShinhan(state) {
      return state.shinhan;
    },
  },
  actions: {
    async [SET_INTEREST](state, payload) {
      state.commit(SET_INTEREST, payload);
    },
    [PURGE_INTEREST_ZERO](state) {
      state.commit(PURGE_INTEREST_ZERO);
    },
    async [SET_INTEREST_SHINHAN](state, payload) {
      state.commit(SET_INTEREST_SHINHAN, payload);
    },
    [PURGE_INTEREST_SHINHAN](state) {
      state.commit(PURGE_INTEREST_SHINHAN);
    },
    async [SET_INSTALLMENT_PROGRAM](state, payload) {
      state.commit(SET_INSTALLMENT_PROGRAM, payload);
    },
    [PURGE_INSTALLMENT_PROGRAM](state) {
      state.commit(PURGE_INSTALLMENT_PROGRAM);
    },
    async [SET_INSTALLMENT_SHINHAN](state, payload) {
      state.commit(SET_INSTALLMENT_SHINHAN, payload);
    },
    [PURGE_INSTALLMENT_SHINHAN](state) {
      state.commit(PURGE_INSTALLMENT_SHINHAN);
    },
  },
  mutations: {
    [SET_INTEREST](state, payload) {
      state.isInterestZero = payload;
    },
    [PURGE_INTEREST_ZERO](state) {
      state.isInterestZero = false;
    },
    [SET_INSTALLMENT_PROGRAM](state, payload) {
      const monthNo = payload.monthNo
        ? (Array.isArray(payload.monthNo)
            ? payload.monthNo
            : payload.monthNo.split(',')
          ).map((current) => {
            return {
              id: current,
              name: `${current} Tháng`,
            };
          })
        : [];
      const prepayPercent = payload.prepayPercent
        ? (Array.isArray(payload.prepayPercent)
            ? payload.prepayPercent
            : payload.prepayPercent.split(',')
          ).map((current) => {
            return {
              id: current,
              name: `${current} %`,
            };
          })
        : [];
      state.program = {
        ...payload,
        monthNo,
        prepayPercent,
      };
    },
    [PURGE_INSTALLMENT_PROGRAM](state) {
      state.program = {
        ...defaultInstallmentProgram,
      };
    },
    [SET_INSTALLMENT_SHINHAN](state, payload) {
      state.shinhan = payload;
    },
    [PURGE_INSTALLMENT_SHINHAN](state) {
      state.shinhan = {
        ...defaultInstallmentShinhan,
      };
    },
    [SET_INTEREST_SHINHAN](state, payload) {
      state.isInterestShinhan = payload;
    },
    [PURGE_INTEREST_SHINHAN](state) {
      state.isInterestShinhan = false;
    },
  },
};
