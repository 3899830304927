const FastSync = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "fast-sync" */ '@/view/pages/fast-sync-log/FastSync.vue'
  );
const FastSyncList = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "fast-sync-list" */ '@/view/pages/fast-sync-log/FastSyncList.vue'
  );

export default [
  {
    path: 'fast-sync',
    component: FastSync,
    children: [
      {
        path: '/',
        name: 'fast-sync-list',
        component: FastSyncList,
        meta: {
          title: 'Trạng thái đồng bộ với Fast - DDV',
        },
      },
    ],
  },
];
