import config from './config.json';

const SERVER = {
  DEVELOPMENT: 'development',
  TEST: 'test',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

/**
 * @name getServer
 *
 * @param {String} rootApiConfig
 * @param {String} version
 * @returns {Object}
 */
export function getServer({ rootApiConfig, version }) {
  const apiBaseUrl = getSettings(rootApiConfig) || null;

  requireFields(rootApiConfig);
  requireVersion(version);

  return {
    ...apiBaseUrl,
    version: version || null,
  };
}

/**
 * @name requireFields
 * @description Kiểm tra cấu hình của file config.json
 *
 * @param {String} rootApiConfig
 */
function requireFields(rootApiConfig) {
  const apiBaseUrl = getSettings(rootApiConfig) || null;

  if (!apiBaseUrl) {
    throw new Error('Cấu hình server không hợp lệ');
  }

  if (!apiBaseUrl.read) {
    throw new Error('Yêu cầu url read');
  }

  if (!apiBaseUrl.write) {
    throw new Error('Yêu cầu url write');
  }
}

/**
 * @name requireVersion
 * @description Bắt buộc có version ở file .env
 * @param {String} version
 */
function requireVersion(version) {
  if (!version) {
    throw new Error('Yêu cầu phiên bản version');
  }
}

/**
 * @name getSettings
 *
 * @param {String} rootApiConfig
 * @returns {Object}
 */
function getSettings(rootApiConfig) {
  let result = {};
  switch (rootApiConfig) {
    case SERVER.DEVELOPMENT:
      result = config[SERVER.DEVELOPMENT];
      break;
    case SERVER.TEST:
      result = config[SERVER.TEST];
      break;
    case SERVER.STAGING:
      result = config[SERVER.STAGING];
      break;
    case SERVER.PRODUCTION:
      result = config[SERVER.PRODUCTION];
      break;
  }
  return { ...result };
}
