import ApiService from '@/core/services/api.service';
const mutations = {
  SET_BILL: 'SET_BILL',
  SET_SELECTED_PRODUCT_WARRANTY: 'SET_SELECTED_PRODUCT_WARRANTY',
  UPSERT_PRODUCT_WARRANTY: 'UPSERT_PRODUCT_WARRANTY',
  SET_SELECTED_BILL_WARRANTY_ITEM: 'SET_SELECTED_BILL_WARRANTY_ITEM',
};

const initialState = {
  bill: {},
  selectedProductWarrantyList: [],
};

export default {
  state: {
    ...initialState,
  },
  actions: {
    fetchSalesReceiptForCheckWarranty({ commit }, payload) {
      commit(mutations.SET_BILL, null);
      if (!payload.billNumber) return;
      const params = {
        isCurrentProduct: true,
      };
      return ApiService.query(`bills/${payload.billNumber}/for-warranty`, {
        params,
      }).then((response) => {
        const bill = response.data.data;
        const billWithItemIsCheck = bill.billItems.map((item) => {
          return {
            ...item,
            isChecked: false,
          };
        });
        commit(mutations.SET_BILL, {
          ...bill,
          billItems: billWithItemIsCheck,
        });
      });
    },
    setSelectedBillWarrantyItem({ commit }, selectedBillWarrantyItem) {
      commit(
        mutations.SET_SELECTED_BILL_WARRANTY_ITEM,
        selectedBillWarrantyItem,
      );
    },
    setSelectedProductWarrantyList({ commit }, payload) {
      commit(mutations.SET_SELECTED_PRODUCT_WARRANTY, payload);
    },
    upsertProductWarranty({ commit }, product) {
      commit(mutations.UPSERT_PRODUCT_WARRANTY, product);
    },
  },
  mutations: {
    [mutations.SET_BILL](state, payload) {
      state.bill = { ...payload };
    },
    [mutations.SET_SELECTED_PRODUCT_WARRANTY](state, payload) {
      state.selectedProductWarrantyList = payload;
    },
    [mutations.UPSERT_PRODUCT_WARRANTY](state, product) {
      const index = state.selectedProductWarrantyList.findIndex(
        (p) => p.id === product.id,
      );
      if (index !== -1) {
        state.selectedProductWarrantyList.splice(index, 1);
      } else {
        state.selectedProductWarrantyList.push(product);
      }
    },
    [mutations.UPSERT_PRODUCT_WARRANTY](state, product) {
      const index = state.selectedProductWarrantyList.findIndex(
        (p) => p.id === product.id,
      );
      if (index !== -1) {
        state.selectedProductWarrantyList.splice(index, 1);
      } else {
        state.selectedProductWarrantyList.push(product);
      }
    },
    [mutations.SET_SELECTED_BILL_WARRANTY_ITEM](
      state,
      selectedBillWarrantyItem,
    ) {
      const billItems = [...state.bill.billItems];
      const index = billItems.findIndex(
        (item) => item.id === selectedBillWarrantyItem.id,
      );
      const isExisted = index > -1;
      if (!isExisted) return;

      billItems[index].isChecked = !billItems[index].isChecked;

      state.bill = {
        ...state.bill,
        billItems,
      };
    },
  },
  getters: {
    billItems: (state) => state.bill.billItems,
    billInfoForCheckWarranty: (state) => {
      return {
        ...state.bill,
      };
    },
    billWarrantyForCreate: (state) => {
      if (
        !state.bill ||
        !state.bill.billItems ||
        !state.bill.billItems.length
      ) {
        return null;
      }

      const checkedBillItems = state.bill.billItems.reduce((result, item) => {
        if (item.isChecked) {
          result.push({
            ...item,
            code: item.productCode,
            merchantId: item.productMerchantId || 1,
            imeiCode: item.imeiNo,
          });
        }
        return result;
      }, []);

      return {
        ...state.bill,
        billItems: checkedBillItems,
      };
    },
    selectedProductWarrantyList: (state) => {
      const checkedBillItems = state.bill.billItems.filter(
        (item) => item.isChecked,
      );
      return checkedBillItems;
    },
  },
};
