// action types
export const SET_PRODUCTS = 'setProducts';
export const PURGE_PRODUCTS = 'removeProducts';
const productsBase = [];
export default {
  state: {
    products: productsBase
  },
  getters: {
    /**
     * get list product
     * @param state
     * @returns {*}
     */
    getProducts(state) {
      return state.products;
    }
  },
  actions: {
    [SET_PRODUCTS](state, payload) {
      state.commit(SET_PRODUCTS, payload);
    },
    [PURGE_PRODUCTS](state) {
      state.commit(PURGE_PRODUCTS);
    }
  },
  mutations: {
    [SET_PRODUCTS](state, payload) {
      state.products = payload;
    },
    [PURGE_PRODUCTS](state) {
      state.products = [];
    }
  }
};
