const actions = {
  fetchExchangeProduct: 'fetchExchangeProduct',
  updateExchangeProduct: 'updateExchangeProduct',
};

const mutations = {
  SET_EXCHANGE_PRODUCT: 'SET_EXCHANGE_PRODUCT',
  ADD_EXCHANGE_PRODUCT: 'ADD_EXCHANGE_PRODUCT',
};

const initialState = {};

export default {
  state: {
    exchangeProduct: {
      ...initialState,
    },
  },
  getters: {
    getExchangeProduct(state) {
      return state.exchangeProduct;
    },
  },
  actions: {
    [actions.fetchExchangeProduct](state, payload) {
      state.commit(mutations.SET_EXCHANGE_PRODUCT, payload);
    },
    [actions.updateExchangeProduct](state, payload) {
      state.commit(mutations.SET_EXCHANGE_PRODUCT, payload);
    },
  },
  mutations: {
    [mutations.SET_EXCHANGE_PRODUCT](state, payload) {
      state.exchangeProduct = { ...payload };
    },
    [mutations.ADD_EXCHANGE_PRODUCT](state) {
      state.exchangeProduct = initialState;
    },
  },
};
